import React, {useState} from "react";
import Header from "../../components/layout/header";
import "./index.scss";
import * as PR from "../../prime-modules/index";
import Footer from "../../components/layout/footer";
import { useNavigate } from 'react-router-dom';
import { useTranslationContext } from "../../context/TranslationContext";
import { createSubscriptionSessionAPI, getPlansDropDownAPI, subscriptionPlans } from "../../services/api";
import { useMutation, useQuery } from "react-query";
import { useToast } from "../../context/ToastContext";
import { setCurrentPlan } from "../../store/app.slice";
import { useDispatch, useSelector } from "react-redux";
import { billingAddress, deviceData } from "../payment/helper";
import { bundlePopularTag, getPlanDescription, randomIdGenerator, setPrice } from "../../utils/reuse";
import { ICCID } from "../../utils/localstore";

const Plans = () => {
  const { labelsIot } = useTranslationContext()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {showToast} = useToast()
  const { firstName, email, phone } = useSelector((state) => state.auth.userData);
  const {name} = useSelector((state) => state.app?.currentPaymentPlan);
  const {data:dropDownData  } = useQuery(['PlansDropDownAPI'],getPlansDropDownAPI,{
    staleTime: 300000, // 5 minutes in milliseconds
  });
  let firstDropDownValue = dropDownData?.dataAmountForDisplay[0]
  const [selectedPackage, setSelectedPackage] = useState(firstDropDownValue);
  const {data, isLoading  } = useQuery(
    ['Plans', selectedPackage || firstDropDownValue],
    subscriptionPlans,{enabled: selectedPackage || firstDropDownValue ? true : false});
  const {isLoading:checkoutLoading, mutate: createCheckoutSessionHandler} = useMutation(createSubscriptionSessionAPI, {
    onSuccess: res => {
      navigate('/payment', {state: {...res}})
    },
    onError: e => {
      showToast("error", e?.message ?? "Something went wrong!")

    }
  })

  console.log(selectedPackage , firstDropDownValue)

  let filterList=[];
  if(!!dropDownData){
    filterList = dropDownData?.dataAmountForDisplay?.map(item=>({name:item,value:item}))
  }

  const handleSelectPlan = (plan,index) =>{
    dispatch(setCurrentPlan({...plan,label:bundlePopularTag[index]}))
      createCheckoutSessionHandler({ request: request(plan), plan });
  }
  const request = (data) => ({
    ...deviceData,
   iccid:localStorage.getItem(ICCID),
    contactInfo: {
      firstName,
      email,
      lastName: "",
      phone
    },
    billingAddress,
    orderSummary: {
      dataPlans: [
        {
          name: data.name,
          description: data.description,
          quantity: 1,
          price: +data.price,
          promoCode: ""
        }
      ],
      totalOrderAmount: +data.price
    },
    paymentGateway: "Stripe",
    paymentMethodId: randomIdGenerator() ,
  })

  const handleSelectDataPlan = (e)=>{
   setSelectedPackage(e.value)
  }

  const monthlyYearlyCost = !!data ? (data[0]?.price / data[0]?.duration) * 12 : undefined;
  // Loop through data and calculate yearly cost for each plan
  !!monthlyYearlyCost && data.map((item, index) => {
    // Calculate yearly cost
    const yearlyCost = (item.price / item.duration) * 12;
    // Calculate savings compared to the Monthly Bundle
    const savings = (monthlyYearlyCost - yearlyCost).toFixed(0);
    data[index].amountSave = savings > 0 ? savings : "0"
  });

  return (
      <section className="auth-wrapper flex flex-column justify-content-between plans-block">
        <Header />
        <div className="content-wrapper">
          <div className="grid grid-nogutter align-items-center w-12">
            <div className="col-12 md:col-12">
              <h1>{labelsIot?.LBLI0011}</h1>
              <p className="plan-des mx-auto">{labelsIot?.LBLI0161 || "Select the amount of data you require per month and choose your preferred bundle from the options below. For optimal performance and seamless device usage, we recommend selecting a higher data plan if needed. Flexible options are available to suit your needs, and you have the freedom to cancel anytime."}</p>
            </div>
          </div>
          <div className="flex justify-content-center">
            <div className="flex align-items-center gap-3 m-flex">
              <p className="text-center m-0 select-data">{labelsIot?.LBLI0163}</p>
              <PR.Dropdown value={selectedPackage || firstDropDownValue} onChange={handleSelectDataPlan} options={filterList} optionLabel="name" 
              placeholder="Select a Plan" className="select-plan-dropdown" panelClassName="data-dropdown-panel" />
            </div>
            </div>
          <div className='bundles-list-section'>
            <div className="list-container">
              <div className="grid justify-content-center">
                {!!monthlyYearlyCost && data?.length > 0 && !isLoading ? data.map((plan, index) => (
                  <div key={index} className={`col-12 md:col-6 sm:col-12 lg:col-3`}>
                    <div className='bundle-box mb-3'>
                      { (index === 1 || index === 2) && (
                        <div className='popular-tag'>
                          <i className="pi pi-star-fill"></i>
                          <span>{bundlePopularTag[index]}</span>
                        </div>
                      )}
                      <h2>{plan?.description}</h2>
                      <h3>{setPrice(plan?.price)}</h3>
                      <p className="mb-2">
                        {selectedPackage || firstDropDownValue || "N/A"} 
                        {(selectedPackage !== "Unlimited" ||
                          firstDropDownValue !== "Unlimited") 
                          ? ` / ${labelsIot.LBLI0165}` 
                          : ""}
                      </p>
                      <p>{labelsIot?.LBLI0013} {getPlanDescription(plan?.description)}</p>
                      <div className='flex align-items-center justify-content-center'>
                        <PR.Button disabled={checkoutLoading} loading={plan.name === name ? checkoutLoading:false} label="Select" className="buy-button" onClick={handleSelectPlan.bind(null,plan,index)} />
                      </div>
                    </div>
                   {plan?.amountSave != 0 && <text className="amount-save">{labelsIot?.LBLI0162 || "Save"} {setPrice(plan?.amountSave)} / {labelsIot?.LBLI0166}</text>}
                  </div>
                )) : !selectedPackage || !firstDropDownValue || isLoading ? (
                  <div className="grid w-12">
                    {[...Array(4)].map((_, i) => (
                      <div className={`col-12 md:col-6 sm:col-12 lg:col-3`}>
                        <div className="bundle-box relative z-5">
                          <PR.Skeleton width="85%" height="2rem" className="mb-6 m-auto" />
                          <PR.Skeleton width="70%" height="4rem" className="mb-3 m-auto" />
                          <PR.Skeleton width="70%" height="1rem" className="mb-2 m-auto" />
                          <PR.Skeleton width="60%" height="1rem" className="mb-5 m-auto" />
                          <PR.Skeleton width="50%" height="3.4rem" className="m-auto" />
                        </div>
                      </div>
                    ))}
                  </div>
                ):<div className="empty-secton">
                <p>{labelsIot?.LBLI0170 || "No plans were found!"}</p>
              </div>}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>

  );
};

export default Plans;