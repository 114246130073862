import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import * as PR from "../../prime-modules/index";
import useDebounce from "../../hooks/useDebounce";
import { globalConfig } from "../../services/api";

const categories = {
  "General Information": [
    {
      header: "What is data2go?",
      children: <p className="m-0"><Link to={`${globalConfig.thingsDomainUrl}`} target="_blank" rel="noopener noreferrer">things.data2go.mobi</Link> provides seamless, reliable SIM Card connectivity for your IoT devices, including cameras, sensors, EV chargers, heat pumps and other connected devices.</p>
    }
  ],
  "Getting Started": [
    {
      header: "How do I activate my SIM card?",
      children: <p className="m-0">
        1. Visit <Link to={`${globalConfig.thingsDomainUrl}`} target="_blank" rel="noopener noreferrer">things.data2go.mobi</Link> or scan the QR code provided on your SIM card. <br />
        2. <span>Create an Account:</span> Follow the instructions to set up your account.<br />
        3. <span>Activate Your Subscription:</span> Choose a data subscription plan and complete the payment process.<br />
        4. <span>Set Up APN:</span> Follow the APN setup guide provided for your device to ensure connectivity.</p>
    }
  ],
  "Subscriptions and Payments": [
    {
      header: "How are payments processed?",
      children: <p className="m-0">All payments are securely processed through Stripe, a trusted payment platform using the latest encryption and security standards.</p>
    },
    {
      header: "How is my data stored?",
      children: <p className="m-0">Your data is securely stored using industry-standard encryption. Payment information is handled by trusted providers like Stripe and complies with global security standards. We do not store sensitive payment details with our customers.</p>
    },
    {
      header: "How do I update my payment information?",
      children: <p className="m-0">
        1. Log in to your account at <Link to={`${globalConfig.thingsDomainUrl}`} target="_blank" rel="noopener noreferrer">things.data2go.mobi</Link>. <br />
        2. Navigate to the <span>"Your devices"</span> section. <br />
        3. Press the elipse menu next to the <span>"Cancel subscription button"</span> Update payment details. <br />
        4. Save the changes to ensure future payments are processed correctly.
      </p>
    },
    {
      header: "How do I get my payment receipt?",
      children: <p className="m-0">
        1.	After completing your payment, a receipt will be automatically sent to the email address associated with your account. <br />
        2.	You can also access your receipts by logging into your account at <Link to={`${globalConfig.thingsDomainUrl}`} target="_blank" rel="noopener noreferrer">things.data2go.mobi</Link>: <br />
        •	Navigate to the Payment History section in the left sidebar.<br />
        •	On the transaction you can choose to download your receipt.
      </p>
    },
    {
      header: "Can I get a refund?",
      children: <p className="m-0">Refunds are typically not available once a payment is processed. However, if you believe there has been an error or issue with your subscription or payment, please contact us at <Link to={`mailto:${globalConfig.supportEmail}`} rel="noopener noreferrer">support@data2go.mobi.</Link> We will review your case and assist you as needed.</p>
    },
    {
      header: "What kind of payments do you offer?",
      children: <p className="m-0">
        We accept the following payment methods: <br />
        •	Major credit and debit cards (Visa & Mastercard) <br />
        •	Apple Pay and Google Pay<br />
        All payments are handled securely via Stripe, ensuring your information is protected.
      </p>
    }
  ],
  "Coverage": [
    {
      header: "Where can I use my SIM card?",
      children: <p className="m-0">Your SIM Card works across Europe and in areas with mobile network coverage, tailored specifically for the service. Note that the SIM Card is locked to your device and cannot be used elsewhere.</p>
    }
  ],
  "Account Management": [
    {
      header: "How do I reset my password?",
      children: <p className="m-0">
        1. On the login screen, click <span>“Forgot Password?”</span>. <br />
        2. Enter the email address associated with your account.<br />
        3. Check your inbox for the password reset link.<br />
        4. Click the link and follow the instructions to create a new password.</p>
    },
    {
      header: "How do I change my password?",
      children: <p className="m-0">You can change your password under your profile settings.</p>
    },
    {
      header: "How do I delete my account?",
      children: <p className="m-0">To delete your account, please contact our support at: <Link to={`mailto:${globalConfig.supportEmail}`} rel="noopener noreferrer">support@data2go.mobi.</Link> We'll assist you with the process to ensure your account and associated data are securely removed.</p>,
    }
  ],
  "Troubleshooting": [
    {
      header: "What should I do if my device isn't connecting?",
      children: <p className="m-0">
        1. Verify that the SIM Card is correctly inserted and securely attached. <br />
        2. Check that the APN settings are configured correctly. <br />
        3. Ensure there is network coverage in your area (check your phone for available networks). <br />
        4. Contact support if the issue persists: <Link to={`mailto:${globalConfig.supportEmail}`} rel="noopener noreferrer">support@data2go.mobi.</Link> <br />
      </p>
    },
    {
      header: "What is an ICCID?",
      children: <p className="m-0">The ICCID is a unique 20-digit number that identifies your SIM Card. It is printed on the SIM Card itself and used for activation and management. The ICCID helps ensure that your SIM Card is associated with the correct account and device.</p>
    }
  ],
};

const FAQ = () => {
  const { labelsIot } = useTranslationContext();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filteredCategories, setFilteredCategories] = useState(categories);

  const searchValue = useDebounce(searchKeyword, 1000);

  useEffect(() => {
    if (searchValue.trim().length === 0) {
      setFilteredCategories(categories);
      return;
    }

    const updatedCategories = Object.keys(categories).reduce((acc, category) => {
      const filteredQuestions = categories[category].filter((item) => {
        const headerMatch = item.header.toLowerCase().includes(searchValue.toLowerCase());

        // Safely convert children to a string
        const childrenContent = React.isValidElement(item.children)
          ? item.children?.props?.children
          : item.children;

        const contentMatch = (childrenContent || "")
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        return headerMatch || contentMatch;
      });

      if (filteredQuestions.length > 0) {
        acc[category] = filteredQuestions;
      }
      return acc;
    }, {});

    setFilteredCategories(updatedCategories);
  }, [searchValue]);

  const splitCategories = () => {
    const allCategories = Object.entries(filteredCategories); // Convert categories to an array for easier splitting.
    const midpoint = Math.ceil(allCategories.length / 2);

    const leftCategories = allCategories.slice(0, midpoint);
    const rightCategories = allCategories.slice(midpoint);

    const renderTabs = (categoriesPart) =>
      categoriesPart.map(([category, questions], index) => (
        <div key={index} className="faq-category">
          <h2 className="category-h2">{category}</h2>
          <PR.Accordion>
            {questions.map((item, i) => (
              <PR.AccordionTab key={i} header={item.header}>
                {item.children}
              </PR.AccordionTab>
            ))}
          </PR.Accordion>
        </div>
      ));

    return {
      leftTabs: renderTabs(leftCategories),
      rightTabs: renderTabs(rightCategories),
    };
  };

  const { leftTabs, rightTabs } = splitCategories();

  return (
    <div className="add-device">
      <div className="content-header flex align-items-center justify-content-between">
        <h1>{labelsIot?.LBLI0137}</h1>
        <div className="search">
          <PR.Button icon="pi pi-search" className="search-button" />
          <PR.InputText
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder={labelsIot?.LBLI0021}
          />
        </div>
      </div>
      {Object.keys(filteredCategories).length > 0 ? (
        <div className="settings-section faq-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-6 left-block">{leftTabs}</div>
            <div className="col-12 md:col-6 right-block">{rightTabs}</div>
          </div>
        </div>
      ) : (
        <div className="empty-section faq-empty-section">
          <p>{labelsIot?.LBLI0138}</p>
        </div>
      )}
    </div>
  );
};

export default FAQ;
