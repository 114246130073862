import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_PAYMENT_PLAN } from "../utils/localstore";

const initialState = {  iccid:"", deviceList:[], paymentList:[], currentPaymentPlan:{} }

const appSlice = createSlice({
    name:'app',
    initialState,
    reducers:{
       setDeviceList(state,{payload}){
        state.deviceList = [...state.deviceList,...payload]
       },
       emptyDeviceList(state,{payload}){
        state.deviceList = []
       },
       setPaymentList(state,{payload}){
        state.paymentList = [...state.paymentList,...payload]
       },
       emptyPaymentList(state,{payload}){
        state.paymentList = []
       },
       setCurrentPlan(state,{payload}){
        state.currentPaymentPlan = payload
        localStorage.setItem(CURRENT_PAYMENT_PLAN,JSON.stringify(payload))
       }
       }
});
export const { setDeviceList,emptyDeviceList, setPaymentList,emptyPaymentList, setCurrentPlan } = appSlice.actions
export default appSlice.reducer