import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

// PublicRoute Component
export const PublicRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search);
  const getIccid = searchParams.get('iccid');

  useEffect(() => {
    if (getIccid) {
      navigate( isAuthenticated ? '/dashboard' : '/signup', {state: {iccid:getIccid}})
    }
  }, [dispatch, getIccid, navigate, isAuthenticated])

  return isAuthenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

// PrivateRoute Component
export const PrivateRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
