import React, { useState, useEffect } from "react";
import html2pdf from 'html2pdf.js';
import * as PR from "../../prime-modules/index";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import { useQuery } from "react-query";
import { getPaymentList } from "../../services/api";
import useDebounce from "../../hooks/useDebounce";
import { useToast } from "../../context/ToastContext";
import { useSelector } from "react-redux";
import { capitalize, handleDownloadReceipt, setPrice } from "../../utils/reuse";
const PaymentReceipt = ({setSelectedSection}) => {

  const {showToast} = useToast()
  const { labelsIot } = useTranslationContext();
  const userData = useSelector(state=>state.auth.userData)
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchValue = useDebounce(searchKeyword, 1000);

  const {data, isLoading, isRefetching } = useQuery(
    ["PaymentList",userData?.userId],
    () =>
      getPaymentList(searchValue),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes,
      retry:1,
      onError:(e)=>{
        showToast("error",e.message ?? "Something went wrong!")
      }
    }
  );

  const [sortList, setSortList] = useState(data)

 // Clear device list and reset page when search keyword changes
  // useEffect(() => {
  //   if(searchValue){
  //     refetch();
  //   }
  // }, [searchValue, dispatch, refetch]);


  useEffect(() => {
      setSortList(!searchValue.trim() ? data: data?.filter(item => item.iccid.includes(searchValue) || item.paymentGateway?.toLowerCase().includes(searchValue.toLowerCase())  ||  item.transactionStatus?.toLowerCase().includes(searchValue.toLowerCase()) || item.totalOrderAmount.toString()?.includes(searchValue.toLowerCase())))
  }, [searchValue, data]);

  const downloadRecept = (rowData) =>{
  const content = handleDownloadReceipt({...rowData, ...userData});
  const options = {
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 3 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };
   html2pdf().set(options).from(content).save(`${rowData?.transactionId}.pdf`);
  }

  const dateTime = (rowData) => {
   const date = new Date(rowData?.createdTs);
   
   // Format date as DD-MM-YYYY
   const formattedDate = date
     .toLocaleDateString("en-GB")
     .split("/")
     .join("-");
   
   // Format time as HH:MM:SS
   const formattedTime = date.toLocaleTimeString("en-GB", { hour12: true });
    return (
      <div className="date-flex">
        <span className="column-name">{labelsIot?.LBLI0066}</span>
        <b>{formattedDate}<span className="date-break"><span>, </span><br /></span>
        {formattedTime}</b>
      </div>
    );
  };
  const action = (rowData) => {
    return (
      <div className="flex align-items-center gap-3">
        <span className={`payment payment-${rowData?.transactionStatus?.toLowerCase()}`}>{capitalize(rowData?.transactionStatus)}</span>
       {rowData?.transactionStatus === "COMPLETED"? <PR.Button onClick={()=>downloadRecept(rowData)} icon="pi pi-download" className="button-trash button-download" />:<div className="button-non-trash"></div>}
      </div>
    );
  };

  const iccid = (rowData) => (
    <>
      <span className="column-name">{labelsIot?.LBLI0018}</span>
      <span>{rowData?.iccid}</span>
    </>
  );

  const totalOrderAmount = (data) => (
    <>
      <span className="column-name">{labelsIot?.LBLI0064}</span>
      {data.totalOrderAmount ? setPrice(data.totalOrderAmount) : "" }
    </>
  );

  const paymentMethod = (rowData) => (
    <>
      <span className="column-name">{labelsIot?.LBLI0065}</span>
      <span>{rowData?.paymentGateway}</span>
    </>
  );

  const transactionId = (rowData) => (
    <>
      <span className="column-name">{labelsIot?.LBLI0067}</span>
      <span>{rowData?.transactionId}</span>
    </>
  );


  return (
    <>
      <div className="add-device">
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0068}</h1>
          <div className="flex align-items-center gap-4 right-block">
            <div className="p-inputgroup flex-1">
              <PR.Button icon="pi pi-search" className="search-button" />
              <PR.InputText
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder={labelsIot?.LBLI0021}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", margin: "10px 0px", color: "white", padding: "10px" }} className="mt-6 header-hide">
          <div style={{ width: "32%", color: "#000", fontWeight: "bolder", padding: "5px" }}>{labelsIot?.LBLI0063}</div>
          <div style={{ width: '22%', color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0064}</div>
          <div style={{ width: '28%', color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0065}</div>
          <div style={{ width: '19%', color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0066}</div>
          <div style={{ width: '14%', color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0067}</div>
          <div style={{ width: '30%', textAlign: "center", color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0026}</div>
        </div>
        <ScrollableDiv className="data-table" height="60vh" style={{ borderTop: "1px solid gray", padding: "5px" }}>
          {isLoading || isRefetching ? <PR.DataTable className="no-header" value={[{}, {}, {}, {}, {}, {}, {}, {}]} loading={isLoading}>
            <PR.Column
              body={() => (<PR.Skeleton width="100%" height="2rem" />)}
              style={{ width: "100%" }}
            />
          </PR.DataTable>:null}
          { !!sortList && !isLoading && !isRefetching && sortList.length > 0 ? (
            <>
            <PR.DataTable className="no-header" value={sortList} loading={isLoading}>
              <PR.Column body={iccid}></PR.Column>
              <PR.Column body={totalOrderAmount} headerClassName="action-column" className="text-center"></PR.Column>
              <PR.Column body={paymentMethod} headerClassName="action-column" className="text-center"></PR.Column>
              <PR.Column body={dateTime} headerClassName="action-column" className="text-center"></PR.Column>
              <PR.Column body={transactionId} headerClassName="action-column" className="text-center "></PR.Column>
              <PR.Column body={action} headerClassName="action-column" className="last-column"></PR.Column>
            </PR.DataTable>
            </>
          ):(!isLoading && !isRefetching && searchValue.length === 0 && (!sortList || sortList?.length === 0)) ?   <div className="empty-secton">
          <p>{labelsIot?.LBLI0132} <br/> {labelsIot?.LBLI0133}</p>
          <PR.Button
            onClick={()=>setSelectedSection("devices")}
            label={labelsIot?.LBLI0134}
            className="add-button"
          />
        </div> : !!searchKeyword ? <div className="empty-secton">
          <p>{labelsIot?.LBLI0138}</p>
        </div>:null}

        </ScrollableDiv>
      </div>
    </>
  );
};

export default PaymentReceipt;


function ScrollableDiv({ children, className, height, style }) {
  return (
    <div className={className} style={{ overflowY: "auto", height, ...style }}>
      {children}
    </div>
  );
}