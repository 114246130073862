import { envConfig } from "../../constants"
import { globalConfig } from "../../services/api"
import { randomIdGenerator } from "../../utils/reuse";

export const deviceData = {
  isEsimCompatible: false,
  deviceInfo: {
    device: "website",
  },
  captureID: "",
  randomId: randomIdGenerator(),
};

export const billingAddress = {
  address: "testsubscript",
  street: "testsubscript",
  city: "testsubscript",
  postCode: "testsubscript",
  country: "testsubscript",
};

export const createPayPalOrder = ({ name, description, dispalyPrice: value }, _, actions) => {
    const currency_code = globalConfig.paymentDefaultCurrency?.toUpperCase()

    const purchaseUnits = {
        purchase_units: [
            {
                amount: { currency_code, value, breakdown: { item_total: { currency_code, value } } },
                items: [{ name, description, unit_amount: { currency_code, value, }, quantity: 1 },],
            },
        ],
        application_context: {
            shipping_preference: "NO_SHIPPING",
        },
    };

    return actions.order.create(purchaseUnits).then(orderID => orderID);
};

export const addressElementOptions = {
    appearance: {
        theme: 'stripe',
        labels: 'floating',
        'rules': {
            '.Input': {
                'backgroundColor': '#fff',
                'border': '1px solid rgb(226, 227, 238)',
                'borderRadius': '8px',
                'padding': '15px 18px',
                'fontSize': '16px',
                'color': '#160033',
                'marginBottom':"10px",
                'boxShadow': 'none',
            },
            '.Input:focus': {
                'boxShadow': 'none'
            },
            '.Label': {
                'fontSize': '0px',
            },
            '.Error': {
                'color': "red"
            },
        },
    },
};

export const addressOptions = {
  mode: "billing",
  autocomplete: {
    mode: "google_maps_api",
    apiKey: envConfig.REACT_APP_GOOGLE_MAP_API_KEY,
  },
  display: {
    name: "full",
  },
  fields: {
    phone: "never",
  },
};

// export const prOptions = (amount) => ({
//     total: {
//         label: "eSIM subscription",
//         amount: parseInt(amount * 100)
//     },
//     country: globalConfig.paymentDefaultCountry,
//     currency: globalConfig.paymentDefaultCurrency,
//     requestPayerName: true,
//     requestPayerEmail: true
// })