import React from "react";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import { Link } from "react-router-dom";

const TermsAndConditions = ({setSelectedSection}) => {
  const { labelsIot } = useTranslationContext();
  return (
    <>
      <div className="add-device">
        <p className="backto-button" onClick={() => setSelectedSection('settings')}><i className="pi pi-arrow-left pr-2"></i> {labelsIot?.LBLI0140}</p>
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0135}</h1>
        </div>
        <div className="content-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-12">
                <p className="sub-heading">Please read these Terms and Conditions carefully and ensure that you understand them.</p>
                <p>This document on Website Use Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, <Link>data2go-IOT</Link> ("Our Site"). Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our Site. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Site immediately. These Terms and Conditions are in the English language only.</p>

                <h2>Definitions and Interpretation:</h2>
                <ul>
                  <li>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</li>
                </ul>

                <h2>Information About Us:</h2>
                <ul>
                  <li>Our Site is owned and operated by DATA2GO LIMITED., a limited company registered in England under company number <b>13154302</b> whose registered office is at <b>115c, Milton Road, CAMBRIDGE CB4 1XE, ENGLAND.</b></li>
                </ul>

                <h2>Access to Our Site:</h2>
                <ul>
                  <li>Access to Our Site is free of charge.</li>
                  <li>It is your responsibility to make any and all arrangements necessary in order to access Our Site.</li>
                  <li>Access to Our Site is provided "as is" and on an "as available" basis. We may alter, suspend or discontinue Our Site (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Site (or any part of it) is unavailable at any time and for any period.</li>
                </ul>

                <h2>Accounts:</h2>
                <ul>
                  <li>Certain parts of Our Site (including the ability to purchase goods from Us) may require an Account in order to access them.</li>
                  <li>You may not create an Account if you are under 18 years of age.</li>
                  <li>When creating an Account, the information you provide must be accurate and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.</li>
                  <li>We recommend that you choose a strong password for your Account. It is your responsibility to keep your password safe. You must not share your Account with anyone else. If you believe your Account is being used without your permission, please contact us immediately at <Link>support@data2go-iot.mobi.</Link> We will not be liable for any unauthorised use of your Account.</li>
                  <li>You must not use anyone else's Account.</li>
                  <li>Any personal information provided in your Account will be collected, used, and held in accordance with your rights and Our obligations under the law, as set out in Clause 16.</li>
                  <li>If you wish to close your Account, you may do so at any time. Closing your Account will result in the removal of your information. Closing your Account will also remove access to any areas of Our Site requiring an Account for access.</li>
                </ul>

                <h2>Intellectual Property Rights:</h2>
                <ul>
                  <li>All Content included on Our Site and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                  <li>Subject to sub-Clauses 5.3 and 5.6 you may not reproduce, copy, distribute, sell, rent, sub-license, store, or in any other manner re-use Content from Our Site unless given express written permission to do so by Us.</li>
                  <li>You may:
                      <ul>
                        <li>Access, view and use Our Site in a web browser (including any web browsing capability built into other types of software or app);</li>
                        <li>Download Our Site (or any part of it) for caching;</li>
                        <li>Print one copy of any of any pages from Our Site;</li>
                        <li>Download extracts from pages on Our Site; and</li>
                        <li>Save pages from Our Site for later and/or offline viewing.</li>
                      </ul>
                  </li>
                  <li>Our status as the owner and author of the Content on Our Site (or that of identified licensors, as appropriate) must always be acknowledged.</li>
                  <li>You may not use any Content saved or downloaded from Our Site for commercial purposes without first obtaining a license from Us (or our licensors, as appropriate) to do so. This does not prohibit the normal access, viewing and use of Our Site for general information purposes whether by business users or consumers.</li>
                  <li>Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III of the Copyrights, Designs and Patents Act 1988 'Acts Permitted in Relation to Copyright Works', covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.</li>
                </ul>
                <h2>Links to Our Site:</h2>
                <ul>
                  <li>
                    You may link to Our Site provided that:
                    <ul>
                      <li>You do so in a fair and legal manner;</li>
                      <li>You do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;</li>
                      <li>You do not use any logos or trademarks displayed on Our Site without Our express written permission; and</li>
                      <li>You do not do so in a way that is calculated to damage Our reputation or to take unfair advantage of it.</li>
                    </ul>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
