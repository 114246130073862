import React, { useEffect, useState } from "react";
import Header from "../../../components/layout/header";
import * as PR from "../../../prime-modules/index";
import { useTranslationContext } from "../../../context/TranslationContext";
import Footer from "../../../components/layout/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { verifyEmail } from "../../../services/api";
import { useToast } from "../../../context/ToastContext";

const VerifyEmail = () => {
  const { labelsIot,validationsIot:validations } = useTranslationContext()
  const [otp, setOtp] = useState("")
  const [otpError, setOtpError] = useState("")
  const {showToast} = useToast()
  const {pathname,search} = useLocation();
  const userId = Number(new URLSearchParams(search).get('id'))
  const navigate = useNavigate();

  useEffect(()=>{
    if(!userId) navigate('/');
 },[userId,navigate]);

  const {mutate,isLoading} = useMutation(verifyEmail.bind(null,`${pathname.replace('-','').slice(1,pathname.length)}`),{
    onSuccess: (o) =>{
    showToast("success","SIGNUP-12")
    setTimeout(() => {
     navigate('/') 
    },2000)
    },
    onError : o =>  showToast("error",o?.message)
});

const submitHandler = () => {
  if(!otp){
    setOtpError("VLDN0022")
    return
  }
  if(otp?.length < 6){
    setOtpError("VLDN0023")
    return
  }
  setOtpError("")
  mutate({userId,code:otp});
};

const handleOtp = (e)=>{
  let value = e?.target?.value
if(value?.length > 6){
  return
}


  setOtpError("")
  setOtp(value)
}


  return (
    <>
      <section className="auth-wrapper">
        <Header />
        <div className="form-wrapper screen-height">
          <div className="grid grid-nogutter align-items-center w-12">
            {/* <div className="col-12 md:col-6 left-block">
              <h1>{labelsIot?.LBLI0157} <br />{labelsIot?.LBLI0158} <br />{labelsIot?.LBLI0159}</h1>
            </div> */}
            <div className="col-12 text-center">
              <div className="form-block verify-email-block">
                <h2>{labelsIot?.LBLI0127}</h2>
                <p>{labelsIot?.LBLI0128}</p>
                  <div autoComplete="off">
                    <div className="card flex flex-column relative gap-2">
                    <PR.InputText
                      name="otp"
                      maxLength={6}
                      value={otp}
                      type={"number"}
                      feedback={false}
                      aria-describedby="otp"
                      placeholder="------"
                      className="otp-input"
                       onChange={(e)=> handleOtp(e)}
                    />
                      {otpError && <span className="err-msg">{ validations[otpError]??'Error'}</span>}
                    </div>
                    <div className="card flex flex-column">
                      <PR.Button disabled={isLoading} type="submit" onClick={submitHandler} label={labelsIot?.LBLI0129} className="login-button" />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default VerifyEmail;