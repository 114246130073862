import React, { useState } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { envConfig } from "../../constants";
import { addressOptions } from "./helper";
import { getPaymentDescription, setPrice, showDate } from "../../utils/reuse";
import { useTranslationContext } from "../../context/TranslationContext";
import { CURRENT_PAYMENT_PLAN } from "../../utils/localstore";

export const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentOptions = ({ price, returnUrl, setIsLoading }) => {
  const navigate = useNavigate()
  const currentPaymentPlan = JSON.parse(localStorage.getItem(CURRENT_PAYMENT_PLAN))
  const { userData } = useSelector((state) => state.auth);
  const { labelsIot } = useTranslationContext()
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();

  const [stripeAddressError, setStripeAddressError] = useState("");
  const [billingDetails, setBillingDetails] = useState({});
  const [cardError, setCardError] = useState("")  
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={paypal_Icon} alt="Paypal" />
  //     </div>
  //   );
  // };
  // const paymentGPay = (options) => {
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={gpay_Icon} alt="GPay" />
  //     </div>
  //   );
  // };
  // const paymentApplePay = (options) => {
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={applepay_Icon} alt="Apple Pay" />
  //     </div>
  //   );
  // };

  const addressChangeHandler = (event) => {
    setStripeAddressError("");
    if (event.complete) {
      setBillingDetails(event.value);
    } else {
      setBillingDetails({});
    }
  };

  const payHandler =  async () => {
    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: billingDetails
        },
        return_url: envConfig.REACT_APP_BASE_URL + returnUrl
      }
    })

    if(!!error) {
      setIsLoading(false);
      setCardError(error?.message)
    } else {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: { email: userData?.email },
    onSubmit: payHandler,
    enableReinitialize: true,
  });

  const defaultValues = {
    name: userData.firstName,
    address: {
      country: localStorage.getItem('iot-country-code')
    }
  };

  return (
    <>
          <p className="backto-button" onClick={() => navigate('/plans')} ><i className="pi pi-arrow-left pr-2"></i> {labelsIot?.LBLI0160}</p>
          <h2 className="-mt-3">{labelsIot?.LBLI0104}</h2>
      <div className="card-section">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="grid w-12">
                <div className="col-12 md:col-6">
                  <h3>{labelsIot?.LBLI0105}</h3>
                  <div className="card flex flex-column mb-4">
                    <PR.InputText
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={labelsIot?.LBLI0082}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div>{formik.errors.email}</div>
                    )}
                  </div>
                  <AddressElement
                    onChange={addressChangeHandler}
                    options={{ ...addressOptions, defaultValues }}
                  />
                  {stripeAddressError && (
                    <div className="err-msg">{stripeAddressError}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <h3>{labelsIot?.LBLI0106}</h3>
                  <PaymentElement
                    options={{
                      layout: "tabs",
                    }}
                  />
                  <div className="err-msg">{cardError}</div>
                  <div className="card flex flex-column mb-4">
                    <p>
                      {labelsIot?.LBLI0112} <strong>{setPrice(price)}</strong> {labelsIot?.LBLI0113} <strong>{getPaymentDescription(currentPaymentPlan?.description)}</strong> {labelsIot?.LBLI0115} <strong>{showDate(state?.subscriptionNextDue)}</strong>
                    </p>
                  </div>
                  <div className="card flex flex-column">
                    <PR.Button
                      label={labelsIot?.LBLI0107}
                      type="submit"
                      className="confirm-button"
                      disabled={formik.isSubmitting}
                    />
                  </div>
                  <div className="card flex flex-column mt-4">
                    <p className="small_p">
                     {labelsIot?.LBLI0108} <Link target='_blank' to="/terms-and-conditions">{labelsIot?.LBLI0109}</Link> {labelsIot?.LBLI0110} <Link target='_blank' to="/privacy-policy">{labelsIot?.LBLI0111}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
    </>
  );
};

export default PaymentOptions;
