// DeviceModal.js
import React, { useEffect, useRef } from "react";
import * as PR from "../../prime-modules/index";
import { Formik, Form } from "formik";
import { newDeviceValidationSchema } from "../../utils/schema";
import Input from "../../components/form/Input";
import { cancelDeviceApi, newDeviceApi, removeDeviceApi } from "../../services/api";
import { useMutation } from "react-query";
import { useToast } from "../../context/ToastContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { ICCID } from "../../utils/localstore";
import { showDate } from "../../utils/reuse";

const ModalDialog = ({ visible, activeSection, onHide, labelsIot, currentDevice, refetch, data }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = useSelector(state=>state.auth.userData);
  const formikRef = useRef(null);
  const { showToast } = useToast()

  const initialNewDevicesValues = {
    tag: "",
    simIconName: "",
    userId: "",
    iccid: ""
  }

  useEffect(() => {
    if(state?.iccid && data?.find(esim => esim?.iccid !== state?.iccid)) {
      formikRef.current?.handleChange("iccid")(state?.iccid);
      navigate("/dashboard", {replace: true, state: {}});
    }
  }, [data, navigate, state])

  const { mutate: newDeviceMutate, isLoading: newDeviceLoading } = useMutation(newDeviceApi, {
    onSuccess: (o) => {
      showToast("success", labelsIot?.LBLI0142)
      formikRef?.current.resetForm();
      onHide()
      refetch()
      navigate('/plans')
    },
    onError: (o) => {
      localStorage.setItem(ICCID,"")
      showToast("error", o?.message)}

  })

  const { mutate: removeDeviceMutate, isLoading: removeDeviceLoading } = useMutation(removeDeviceApi, {
    onSuccess: (o) => {
      showToast("success", labelsIot?.LBLI0143)
      onHide()
      refetch()
    },
    onError: (o) => showToast("error", o?.message)

  })

  const { mutate: cancelDeviceSubscription, isLoading: cancelDeviceLoading } = useMutation(cancelDeviceApi, {
    onSuccess: (o) => {
      showToast("success", labelsIot?.LBLI0043 + " " + moment(o?.validUntil).format("DD MMM YYYY h:mm a"))
      onHide()
      refetch()
    },
    onError: (o) => showToast("error", o?.message)
  })

  const handleSelectPlan = () => {
    navigate('/plans')
  }

  const getModalContent = () => {
    switch (activeSection) {
      case 'addDevice':
        return {
          header: <h2><span className="add-icon">+</span>{labelsIot?.LBLI0020}</h2>,
          content: <div className="device-section-wrapper">
            <Formik initialValues={initialNewDevicesValues}
              validationSchema={newDeviceValidationSchema}
              onSubmit={(values)=>{
              let tempPrams = {...values}
              tempPrams.iccid = tempPrams.iccid.toString()
              tempPrams.simIconName = tempPrams.simIconName?.code
              tempPrams.userId = userData?.userId
              localStorage.setItem(ICCID,tempPrams.iccid)
              newDeviceMutate(tempPrams)
              }} innerRef={formikRef}>
              <Form autoComplete="off">

                <div className="card flex flex-column gap-2 mb-4">
                  <Input
                    label={labelsIot?.LBLI0030}
                    name="tag"
                    type="text"
                    aria-describedby="name"
                    placeholder={labelsIot?.LBLI0031}
                  />
                </div>

                {/* <div className="card flex flex-column gap-2 mb-4">
                  <Input
                        label={labelsIot?.LBLI0032}
                        name="simIconName"
                        type="option"
                        aria-describedby="type"
                        options={deviceName} 
                        optionLabel="name" 
                        className="language-dropdown" 
                        placeholder={labelsIot?.LBLI0033}
                      />
                  
                </div> */}
                <div className="card flex flex-column gap-2">
                  <Input
                    label={labelsIot?.LBLI0034}
                    type="text"
                    maxLength="22"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    }}
                    name="iccid"
                    aria-describedby="iccid"
                    placeholder={labelsIot?.LBLI0035}
                  />
                </div>
                <div className="card flex flex-column">
                  <PR.Button disabled={newDeviceLoading} label={labelsIot?.LBLI0036} className="device-confirm-button" />
                </div>
              </Form>
            </Formik>
          </div>
        };
      case 'cancelSubscription':
        return {
          header: <h2><span><i className="pi pi-minus-circle"></i></span>{labelsIot?.LBLI0037}</h2>,
          content: <div className="cancel-subscription">
            <h3>{labelsIot?.LBLI0038}</h3>
            <p>{labelsIot?.LBLI0039}</p>
            <p>{labelsIot?.LBLI0040}: {labelsIot?.LBLI0041}</p>
            <div className="card flex flex-column">
              <PR.Button disabled={cancelDeviceLoading} onClick={() => cancelDeviceSubscription(
                currentDevice?.orderid !== "" ? {
                  iccid:currentDevice?.iccid,
                  subscription:currentDevice?.name,
                  orderid: currentDevice?.orderid
                }:{
                iccid:currentDevice?.iccid,
                subscription:currentDevice?.name,
              })} label={labelsIot?.LBLI0042} className="device-confirm-button" />
            </div>
            <span className="error-message"> {labelsIot?.LBLI0043} {showDate(data.find(item=>item.iccid == localStorage.getItem(ICCID))?.subscriptionNextDue)}</span>
          </div>
        };
      case 'renewSubscription':
        return {
          header: <h2><span><i className="pi pi-refresh"></i></span>{labelsIot?.LBLI0044}</h2>,
          content: <div className="renew-subscription">
            <p>{labelsIot?.LBLI0045} {showDate(data.find(item=>item.iccid == localStorage.getItem(ICCID))?.subscriptionNextDue)} <br /> {labelsIot?.LBLI0046}</p>
            <div className="card flex flex-column">
              <PR.Button onClick={handleSelectPlan} label={labelsIot?.LBLI0047} className="device-confirm-button" />
            </div>
          </div>
        };
      case 'removeDevice':
        return {
          header: <h2><span><i className="pi pi-trash"></i></span>{labelsIot?.LBLI0048}</h2>,
          content: <div className="cancel-subscription">
            <h3>{labelsIot?.LBLI0049} ({currentDevice?.iccid}) </h3>
            <div className="card flex flex-column">
              <PR.Button disabled={removeDeviceLoading} onClick={() => removeDeviceMutate(currentDevice.iccid)} label={labelsIot?.LBLI0050} className="device-confirm-button" />
            </div>
            {/* <span className="error-message">{labelsIot?.LBLI0051}</span> */}
          </div>
        };
      default:
        return { header: null, content: null };
    }
  };

  const { header, content } = getModalContent();

  return (
    <PR.Dialog visible={visible} modal draggable={false} header={header} onHide={onHide} maskClassName="add-device-dialog">
      <div className="modal-content">{content}</div>
    </PR.Dialog>
  );
};

export default ModalDialog;