import React, { useState, useEffect, useRef, useCallback } from "react";
import EdiText from 'react-editext';
import * as PR from "../../prime-modules/index";
import ModalDialog from "./ModalDialog";
import { simIcon } from "../../assets/images";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import { useMutation, useQuery } from "react-query";
import { editTag, esimsV2API, fetchEsimDetails } from "../../services/api";
import useDebounce from "../../hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../context/ToastContext";
import { ICCID } from "../../utils/localstore";
import { capitalize, showDate, validateTagValue, checkDataSize } from "../../utils/reuse";
import PaymentSettings from "./PaymentSettings";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { envConfig } from "../../constants";
import moment from "moment";

const stripePublicKey = await loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const DevicesList = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {showToast} = useToast()
  const { labelsIot } = useTranslationContext();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");
  const [selectDevice, setSelectDevice] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const [currentDevice, setCurrentDevice] = useState({})
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchValue = useDebounce(searchKeyword, 1000);
  const [stripePayError, setStripePayError] = useState("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const actions = useRef(null);
  const [selectedData, setSelectedData] = useState("");
  const [data, setData] = useState([]);
  const [sortList, setSortList] = useState([])
  const [sortingLoading, setSortLoading] = useState(false)
 
  const sortByDate = (a, b) => moment(b.createdTs).diff(moment(a.createdTs))

  const { refetch, isLoading, isRefetching } = useQuery({
    queryFn: esimsV2API,
    refetchOnWindowFocus: false,
    onSuccess: async (list) => {
      if (list?.length > 0) {
        list = list?.sort(sortByDate);
        setSortLoading(true)
        const updatedData = await Promise.all(
          list.map(async (item) => {
              const esimResponse = await fetchEsimDetails(item.iccid);
              return { ...esimResponse, ...item };
          })
      );
      setData([...updatedData]);
      }
    }
  });

  useEffect(() => {
    if(!data?.length){
      refetch()
    }
  }, [data?.length, refetch])

  // Clear device list and reset page when search keyword changes
  useEffect(() => {
      setSortList(!searchValue.trim() ? data : data?.filter(item =>  item.iccid.includes(searchValue) || item.tag?.toLowerCase().includes(searchValue.toLowerCase()) || item.subscriptionStatus?.toUpperCase().includes(searchValue.toUpperCase())));
      setSortLoading(false)
  }, [searchValue, data]);

  const handleButtonClick = useCallback((section,rowData) => {
    setActiveSection(section);
    storeCurrentDevice(rowData ?? selectedData)
    setVisible(true);
  }, [selectedData]);

  const { mutate } = useMutation(editTag, {
    onSuccess: () => {
      showToast("success",labelsIot?.LBLI0141)
    },
    onError: (errorMsg) => showToast("error", errorMsg.message)
  });


  const storeCurrentDevice = (data)=>{
    localStorage.setItem(ICCID,data?.iccid)
    if(!!data) setCurrentDevice({iccid:data?.iccid, name:data?.name, orderid:data?.orderId })
  }


  const tagHandleSave = (rowData, value,setTagErrorMessage) => {
    let validationState = validateTagValue(value)
    if(!validationState){
     setTagErrorMessage("LBLI0156")
   return
    }
    setTagErrorMessage("")
   
     if (validationState && rowData.tag !== value) {
      rowData.tag = value;
       const params = {
         userId: rowData?.userId,
         iccid: rowData?.iccid,
         tag: value,
       };
       mutate(params);
     }
   };

  const Devices = ({rowData}) => {
    const [tagErrorMessage, setTagErrorMessage] = useState("")

    return (
    <div className="flex align-items-center gap-3">
      <div className="device-icon">
        <PR.Image src={simIcon} alt="Device" />
      </div>
      <p>
        <EdiText
        name="tag"
        value={rowData?.tag}
        type="text"
        className="tag-edit"
        onCancel={(e) => e}
        onSave={(e) => tagHandleSave(rowData, e, setTagErrorMessage)}
        viewContainerClassName='custom-view-wrapper'
        saveButtonClassName="custom-save-button"
        editButtonClassName="custom-edit-button"
        cancelButtonClassName="custom-cancel-button"
        viewProps={{
          className: 'custom-view-class'
        }}
      />
        {tagErrorMessage ? <span style={{color:"red", fontSize:12,margin:0}}>{labelsIot[tagErrorMessage]}</span>:null}
        <span>{labelsIot?.LBLI0018} : #{rowData?.iccid}</span>
      </p>
    </div>
  )
}

  const status = (rowData) => {
    let status = rowData?.subscriptionStatus === "CANCELLING" ? "ACTIVE" :  rowData?.subscriptionStatus
    return(
    status ? 
    <span className={`status-badge status-${status?.toLowerCase()}`}>
      {capitalize(status)}
    </span> : 'N/A'
  )};
  const deviceType = (rowData) => {
    if(!rowData?.deviceType){
      return <>
      <span className="column-name">{labelsIot?.LBLI0153}</span>
      {"N/A"}
      </>
    }
   // const deviceTypeName = deviceName.find((item)=>item.code === rowData?.simIconName)
    return <>
    <span  className="column-name">{labelsIot?.LBLI0153}</span>
    {rowData?.deviceType || "N/A"}
    </>
  };
  const plan = (rowData) => {
    let bundle={};
    if(rowData?.activeBundlesData.length > 1){
      bundle = rowData?.activeBundlesData.sort(
        (a, b) => new Date(b.bundleStartTime) - new Date(a.bundleStartTime)
      );
    }else{
      bundle = rowData?.activeBundlesData?.[0];
    }
    const isInactive = rowData?.subscriptionStatus === "INACTIVE";
    const isUnlimited = bundle?.isUnlimited;
    const nextDue = rowData?.subscriptionNextDue;
    
    const allowanceText = !isUnlimited && nextDue
      ? `(${labelsIot?.LBLI0168} ${moment(nextDue).format("d")} ${labelsIot?.LBLI0169})`
      : null;
  
    const dataUsage = isUnlimited 
      ? "Unlimited" 
      : `${checkDataSize(bundle?.remainingData)} / ${checkDataSize(bundle?.totalData)} ${labelsIot?.LBLI0164}`;
  
    return (
     <div className="justify-center flex flex-col m-flex">
        <span className="column-name text-center">{labelsIot?.LBLI0024}</span>
        {isInactive && rowData?.activeBundlesData.length === 0 ? <span className="no-data-span">-</span> : <span className={`font-thin text-center ${isInactive ? "text-gray-grid" : ""}`}>
          {bundle?.description} <br />
          {dataUsage} <br />
          {allowanceText && (
            <span className="text-xs font-normal text-gray-600 text-center">{allowanceText}</span>
          )}
        </span>}
      </div>
    );
  };

  const date = (rowData) => (
    <div className="flex justify-center m-flex">
      <span className="column-name">{labelsIot?.LBLI0025}</span>
      {rowData?.activeBundlesData.length === 0 || !rowData.subscriptionNextDue ? <span className="no-data-span">-</span> : <text className={`text-center font-thin ${rowData?.subscriptionStatus === "INACTIVE" && "text-gray-grid"}`}>{rowData?.subscriptionNextDue ? rowData?.subscriptionStatus === "CANCELLING" ? <span>{labelsIot?.LBLI0167} <br /></span> : null : ""} {showDate(rowData.subscriptionNextDue) }</text> } 
    </div>
  );

  const handleSelectPlan = (rowData) => {
    storeCurrentDevice(rowData)
    navigate("/plans")
  };
  const action = (rowData) => {
    let statusButton;
    switch (rowData.subscriptionStatus) {
      case "TRAIL":
      case "INACTIVE":
        statusButton = (
          <PR.Button
            label={rowData?.activeBundlesData.length === 0 ? labelsIot.LBLI0027 : labelsIot?.LBLI0029}
            className="subscription-button"
            onClick={()=>handleSelectPlan(rowData)}
          />
        );
        break;
      case "ACTIVE":
        statusButton = (
          <PR.Button
            label={labelsIot?.LBLI0028}
            className="subscription-button"
            onClick={() =>  handleButtonClick("cancelSubscription",rowData)}
          />
        );
        break;
      case "RENEW":
        case "CANCELLING":
        statusButton = (
          <PR.Button
            label={labelsIot?.LBLI0029}
            className="subscription-button"
            onClick={() =>  handleButtonClick("renewSubscription",rowData)}
          />
        );
        break;
      default:
        statusButton = null;
    }
    const isCancellable = selectedData?.subscriptionStatus !== "ACTIVE";
    const isDeletable = selectedData?.subscriptionStatus === "ACTIVE" || selectedData?.subscriptionStatus === "CANCELLING";

    return (
      <div className="action-buttons flex align-items-center gap-2">
        {statusButton}

        <PR.Button
          type="button"
          icon="pi pi-ellipsis-v"
          className="ellipsis-button"
          onClick={(e) => {
            setSelectedData(rowData);
            actions.current.toggle(e);
          }}
        />
        <PR.OverlayPanel ref={actions}>
          <PR.Button
          className={`button-trash ${isCancellable ? 'ban-mouse' : ''}`}
          label={labelsIot?.LBLI0148}
          disabled={isCancellable}
          onClick={() => setShowPaymentMethod(true)}
        />
          <hr className="divider" />
          <PR.Button
          className={`button-trash ${isDeletable ? 'ban-mouse' : ''}`}
          label={labelsIot?.LBLI0150}
          disabled={isDeletable}
          onClick={() => handleButtonClick("removeDevice")}
          tooltip={
            rowData?.subscriptionStatus === "ACTIVE"
              ? labelsIot?.LBLI0147
              : null
          }
          tooltipOptions={{
            showOnDisabled: true,
            position: "bottom",
            className: "trash-button-tooltip",
          }}
        />
        </PR.OverlayPanel>
      </div>
    );
  };
  const headerPaymentMethodElement = (
    <div className="flex align-items-center gap-3">
      <h2><span><i className="pi pi-credit-card"></i></span>{labelsIot?.LBLI0148}</h2>
    </div>
  );

    useEffect(() => {
      if(state?.iccid) {
        handleButtonClick("addDevice") 
      }
    }, [data, handleButtonClick, state])
  
  return (
    <>
      <div className="add-device">
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0019}</h1>
          <div className="flex align-items-center gap-4 right-block">
            <div className="p-inputgroup flex-1">
              <PR.Button icon="pi pi-search" className="search-button" />
              <PR.InputText
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder={labelsIot?.LBLI0021}
              />
            </div>
            <PR.Button
              label={labelsIot?.LBLI0020}
              icon="pi pi-plus"
              className="add-device-button"
              onClick={() => handleButtonClick("addDevice")}
            />
          </div>
        </div>
        <div style={{ display: "flex", margin: "10px 0px", color: "white", padding: "10px" }} className="mt-6 header-hide">
          <div style={{ width: "32.5%", color: "#000", fontWeight: "bolder", padding: "5px" }}>{labelsIot?.LBLI0022}</div>
          <div style={{ width: "16%", paddingLeft: "20px", color: "#000", fontWeight: "bolder", padding: "5px" }}>{labelsIot?.LBLI0153}</div>          
          <div style={{ width: '12%', paddingLeft: "20px", color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0023}</div>
          <div style={{ width: '11%', paddingLeft: "20px", color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0024}</div>
          <div style={{ width: '12%', paddingLeft: "20px", color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0025}</div>
          <div style={{ width: '22%', textAlign: "center", color: "#000", fontWeight: "bolder", padding: '5px' }}>{labelsIot?.LBLI0026}</div>
        </div>
        <ScrollableDiv className="data-table" height="60vh" style={{ borderTop: "1px solid gray", padding: "5px" }}>
        {isLoading || isRefetching || !sortList || sortList.length === 0 ? <PR.DataTable className="no-header" value={[{},{},{},{},{},{},{},{}]} loading={true}>
              <PR.Column
                body={() => (<PR.Skeleton width="100%" height="2rem" />)}
                style={{ width: "100%" }}
              />
            </PR.DataTable>:null}
          {!!sortList && !isLoading && !isRefetching && sortList.length > 0 ? (
            <>
            <PR.DataTable className="no-header" value={sortList} loading={isLoading}>
              <PR.Column body={(rowData)=><Devices rowData={rowData}/>} className="devices" />
              <PR.Column body={deviceType} className="plan" />
              <PR.Column body={status} className="status" />
              <PR.Column field="plan" body={plan} className="plan" />
              <PR.Column field="date" body={date} className="date" />
              <PR.Column body={(rowData) => (action(rowData))} headerClassName="action-column" className="last-column" />
            </PR.DataTable>
            </>
          ):(!isLoading && !isRefetching && !sortingLoading && searchValue.length === 0 && (!data || data?.length === 0)) ? <div className="empty-secton">
          <p>{labelsIot?.LBLI0130} <br/> {labelsIot?.LBLI0131}</p>
          <PR.Button
            label={labelsIot?.LBLI0020}
            icon="pi pi-plus"
            className="add-button"
            onClick={() => handleButtonClick("addDevice")}
          />
        </div>:!!searchKeyword ? <div className="empty-secton">
          <p>{labelsIot?.LBLI0138}</p>
        </div> : null}
        </ScrollableDiv>
      </div>
      <ModalDialog
        visible={visible}
        labelsIot={labelsIot}
        refetch={refetch}
        currentDevice={currentDevice}
        activeSection={activeSection}
        onHide={() => setVisible(false)}
        value={value}
        setValue={setValue}
        selectDevice={selectDevice}
        setSelectDevice={setSelectDevice}
        data={data}
      />
          <PR.Dialog
     visible={showPaymentMethod}
     onHide={() => setShowPaymentMethod(false)}
     draggable={false}
     header={headerPaymentMethodElement}
     baseZIndex={1}
     maskClassName="add-device-dialog"
   >
    <Elements stripe={stripePublicKey}>
      <PaymentSettings setStripePayError={setStripePayError} stripePayError={stripePayError} setShowPaymentMethod={setShowPaymentMethod} iccid={selectedData?.iccid} />
    </Elements>
    </PR.Dialog>
    </>
  );
};

export default DevicesList;

function ScrollableDiv({ children, className, height, style }) {
  return (
    <div className={className} style={{ overflowY: "auto", height, ...style }}>
      {children}
    </div>
  );
}
