import React from "react";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import { Link } from "react-router-dom";

const PrivacyPolicy = ({setSelectedSection}) => {
  const { labelsIot } = useTranslationContext();
  return (
    <>
      <div className="add-device">
        <p className="backto-button" onClick={() => setSelectedSection('settings')}><i className="pi pi-arrow-left pr-2"></i> {labelsIot?.LBLI0140}</p>
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0136}</h1>
        </div>
        <div className="content-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-12">
                <p><Link>data2go-IOT</Link> ("Our Site") understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, Our Site and will only collect and use personal data in ways that are described here and in a way that is consistent with our obligations and your rights under the law. This Privacy Policies is in the English language only. Please read this Privacy Policy carefully and ensure that you understand it. You will be deemed to have accepted the terms of this Privacy Policy when you visit Our Site.</p>

                <h2>Information About Us</h2>
                <p>Our Site is owned and operated by DATA2GO LIMITED., a limited company registered in England under company number <b>13154302</b> whose registered office is at <b>115c, Milton Road, CAMBRIDGE CB4 1XE, ENGLAND.</b></p>
                <h2>What does this Policy cover?</h2>
                <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                <h2>What is personal data?</h2>
                <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018 or any successor legislation (collectively, “the Data Protection Legislation”) as 'any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier'.</p>
                <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                <h2>What are my rights?</h2>
                <p>Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:</p>
                <ul>
                    <li>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions.</li>
                    <li>The right to access the personal data we hold about you. Part 11 will tell you how to do this.</li>
                    <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us to find out more.</li>
                    <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us to find out more.</li>
                    <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                    <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                    <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
                    <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</li>
                    <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
                </ul>
                <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us.</p>
                <p>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</p>
                <p>Further information about your rights can also be obtained from the Information Commissioner's Office or your local Citizens Advice Bureau.</p>
                <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office. We would welcome the opportunity to resolve your concerns ourselves, so please contact us first.</p>
                <h2>What data do you collect and how?</h2>
                <p>Depending upon your use of Our Site, we may collect and hold some or all of the personal data set out in the table below, using the methods also set out in the table. We do not collect any 'special category' or 'sensitive' personal data or personal data relating to children and/or data relating to criminal convictions and/or offenses. We may hold your passport details</p>
                <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                <ul>
                  <li><b>Identity Data</b> includes first name, last name, username or similar identifier, marital status, title, date of birth, gender and passport details.</li>
                  <li><b>Contact Data</b> includes billing address, delivery address, email address and telephone numbers.</li>
                  <li><b>Financial Data</b> includes bank account and payment card details.</li>
                  <li><b>Transaction Data</b> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                  <li><b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
                  <li><b>Profile Data</b> includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
                  <li><b>Usage Data</b> includes information about how you use our website, products and services.</li>
                  <li><b>Marketing and Communications Data</b> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
