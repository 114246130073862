import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import FAQ from "../dashboard/FAQ";
import "./index.scss";

const FAQPage = ({ }) => {
  return (
    <>
      <Header />
      <section className="faq-page-section">
        <FAQ />
      </section>
      <div className="footer-block">
        <Footer />
      </div>
    </>
  );
};

export default FAQPage;