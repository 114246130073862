import React, { useEffect, useState } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentOptions from "./PaymentOptions";
import { envConfig } from "../../constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { addressElementOptions } from "./helper";
import PaymentProvider from "./PaymentProvider";

export const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      if(!state?.sessionClientSecret || !state?.orderID) {
        navigate("/");
      }
    }, [navigate, state?.orderID, state?.sessionClientSecret])

    return (
      <PaymentProvider isLoading={isLoading} >
        <Elements
        stripe={stripePromise}
        options={{
          clientSecret: state?.sessionClientSecret,
          ...addressElementOptions,
          locale: localStorage.getItem("i18nextLng"),
        }}
      >
     <PaymentOptions
          price={state?.price}
          returnUrl={`/payment-success?orderid=${state?.orderID}&random_id=${state?.randomId}`}
          setIsLoading={setIsLoading}
        />
      </Elements>
      {/* <PaymentSuccess/> */}
      </PaymentProvider>
    );
};

export default Payment;